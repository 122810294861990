@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  font-family: "Montserrat";
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  background: rgb(238, 241, 255);
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

#hero {
  font-family: "Montserrat";
  background-color: #4a90e2;
  color: white;
  text-align: center;
  padding: 100px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#hero div {
  margin: 10px 0;
}

.viewProject {
  background-color: #000000;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  margin-top: 20px;
  transition: all 0.3s ease; /* Add transition for all properties */
  display: inline-block; /* Ensure the button is inline-block */
  position: relative; /* Required for pseudo-element positioning */
  overflow: hidden; /* Ensure the pseudo-element is contained */
}

.viewProject::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  background-color: #1d1d1d;
  z-index: -1;
  transition: left 0.3s ease;
}

.viewProject:hover::after {
  left: 0;
}

.viewProject:hover {
  color: white; /* Ensure text color remains white on hover */
}

html {
  scroll-behavior: smooth;
}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  /* width: 100vw; */
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

h1 {
  font-family: "Montserrat", serif;
  font-size: 7rem;
  font-weight: 400;
  line-height: 0.8;
  margin: 0;
}

h2 {
  font-family: "Montserrat", serif;
  font-size: 5rem;
  font-weight: 400;
  margin: 0;
  line-height: 0.8;
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 400;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 225px 225px 225px 225px 225px;
  margin: 20px auto;
}

.container .box {
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 34px 35px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;}

img.socialIcon {
  height: 30px;
  width: 30px;
}



/* animate scroll down arrow */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
  
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin: 0 15px;
}

section {
  padding: 50px;
  text-align: center;
}

section h2 {
  font-size: 40px;
}
#about {
  padding: 20px;
}

.title {
  font-size: 48px;
  text-align: center;
  margin-bottom: 20px;
}

.skills-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center; /* Center align items vertically */
  justify-items: center; /* Center align items horizontally */
}

.skills-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skills-list li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }
}
