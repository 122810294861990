@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-6 {
  bottom: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.me-4 {
  margin-inline-end: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.h-auto {
  height: auto;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: .5rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-b-3xl {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.bg-hero-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(74 144 226 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-hero-bg {
  --tw-gradient-from: #4a90e2 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a90e200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6 var(--tw-gradient-to-position);
}

.to-cyan-500 {
  --tw-gradient-to: #06b6d4 var(--tw-gradient-to-position);
}

.to-emerald-500 {
  --tw-gradient-to: #10b981 var(--tw-gradient-to-position);
}

.to-gray-500 {
  --tw-gradient-to: #6b7280 var(--tw-gradient-to-position);
}

.to-green-500 {
  --tw-gradient-to: #22c55e var(--tw-gradient-to-position);
}

.to-indigo-500 {
  --tw-gradient-to: #6366f1 var(--tw-gradient-to-position);
}

.to-pink-500 {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position);
}

.to-purple-500 {
  --tw-gradient-to: #a855f7 var(--tw-gradient-to-position);
}

.to-red-500 {
  --tw-gradient-to: #ef4444 var(--tw-gradient-to-position);
}

.to-rose-500 {
  --tw-gradient-to: #f43f5e var(--tw-gradient-to-position);
}

.to-yellow-500 {
  --tw-gradient-to: #eab308 var(--tw-gradient-to-position);
}

.p-4 {
  padding: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-32 {
  padding-top: 8rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html, body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  background: #eef1ff;
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  overflow-x: hidden;
}

#hero {
  color: #fff;
  text-align: center;
  background-color: #4a90e2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  font-family: Montserrat;
  display: flex;
}

#hero div {
  margin: 10px 0;
}

.viewProject {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.viewProject:after {
  content: "";
  z-index: -1;
  background-color: #1d1d1d;
  transition: left .3s;
  position: absolute;
  inset: 0 0 0 100%;
}

.viewProject:hover:after {
  left: 0;
}

.viewProject:hover {
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

div#main {
  width: 100vw;
  flex-direction: column;
  display: flex;
}

section {
  position: relative;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  margin: 0;
  font-family: Montserrat, serif;
  font-size: 7rem;
  font-weight: 400;
  line-height: .8;
}

h2 {
  margin: 0;
  font-family: Montserrat, serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: .8;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  grid-gap: 20px;
  grid-template-columns: 225px 225px 225px 225px 225px;
  margin: 20px auto;
  display: grid;
}

.container .box {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  display: flex;
  box-shadow: 0 34px 35px #00000040, 0 -12px 30px #0000001f, 0 4px 6px #0000001f, 0 12px 13px #0000002b, 0 -3px 5px #00000017;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

section {
  text-align: center;
  padding: 50px;
}

section h2 {
  font-size: 40px;
}

#about {
  padding: 20px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 48px;
}

.skills-grid {
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 1rem;
  display: grid;
}

.skills-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.skills-list li {
  border-bottom: 1px solid #ddd;
  padding: .5rem 0;
}

@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }
}

.hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (min-width: 768px) {
  .md\:me-6 {
    margin-inline-end: 1.5rem;
  }
}

/*# sourceMappingURL=index.daa2b8ff.css.map */
